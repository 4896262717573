<template>
  <div
    style="height: inherit"
    class="user-store-list"
  >
    <location-bar v-if="$store.getters['app/windowSize'] <= 767" />
    <!-- <user-addresses @get-store-list="getStoreList" /> -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ $t('Showing') }} {{ filteredStore.length }} {{ $t('of') }} {{ storeList.length }} {{ $t('results') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="searchQuery"
              placeholder="Search..."
              class="search-product"
              @keyup="beginSearch"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <div
      v-if="storeList.length === 0"
      class="mx-auto"
    >
      <img
        class="no-store-found-image"
        :src="require('@/assets/images/app-images/NoOptionAvailable.svg')"
      >
      <p class="text-center mt-3">
        {{ $t('Sorry, Currently no options are available for your selection') }}
      </p>
    </div>

    <!-- Prodcuts -->
    <section
      v-if="storeList.length > 0"
      :class="itemView"
    >
      <b-card
        v-for="product in filteredStore"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <div class="item-img text-center set-extra-images">
          <b-img
            v-if="isFoodCategory(product) && $helpers.getIcons(product.is_veg ? (product.is_veg === 'Non Veg' ? 'non-veg' : 'veg') : '')"
            style="position: absolute; top: 0; left: 0"
            class="food-type-image"
            :src="$helpers.getIcons(product.is_veg ? (product.is_veg === 'Non Veg' ? 'non-veg' : 'veg') : '')"
          />
          <figure
            @mouseenter="$superApp.helper.zoomMagic"
            @mouseout="$superApp.helper.zoomOut"
          >
            <v-zoomer
              :zoomed.sync="$superApp.zoomed"
              :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom"
            >
              <b-img
                :alt="`${product.store_name}-${product.id}`"
                fluid
                class="card-img-top"
                :src="getStoreImage(product)"
                @error="$helpers.imageError"
              />
            </v-zoomer>
          </figure>
        </div>

        <div class="w-100" :class="{'d-flex align-items-start flex-column': itemView === 'list-view'}">
          <!-- Product Details -->
          <b-card-body :class="{'mb-auto': itemView === 'list-view'}">
            <div class="item-wrapper">
              <div class="item-rating">
                <ul class="unstyled-list list-inline">
                  <li
                    v-for="star in 5"
                    :key="star"
                    class="ratings-list-item"
                    :class="{ 'ml-25': star - 1 }"
                  >
                    <feather-icon
                      icon="StarIcon"
                      size="16"
                      :class="[{ 'fill-current': star <= product.rating }, star <= product.rating ? 'text-warning' : 'text-muted']"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <h6 class="item-name">
              <b-link
                class="text-body"
                :to="{ path: getStoreUrl(product) }"
              >
                {{ product.store_name }}
              </b-link>
            </h6>
            <b-card-text
              class="item-description mb-0"
              :class="{ 'opacity-0': !getCategory(product) }"
            >
              {{ getCategory(product) ? getCategory(product) : 'N/A' }}
            </b-card-text>
            <b-card-text class="item-description product-timings-data">
              <span>
                <img
                  style="width: 16px"
                  :src="require('@/assets/images/app-icons/order.png')"
                >
                Min Order {{ product.offer_min_amount }}
              </span>
              <span
                v-if="product.storetype.category === 'FOOD'"
                class="ml-1"
              >
                <img
                  style="width: 16px"
                  :src="require('@/assets/images/app-icons/delivery-box.png')"
                >
                {{ product.estimated_delivery_time }} Minutes
              </span>
            </b-card-text>
          </b-card-body>

          <div class="item-options text-center">
            <b-button
              v-if="!isThirdParty(product)"
              :to="{ path: getStoreUrl(product) }"
              :variant="product.shopstatus === 'OPEN' ? 'primary' : 'danger'"
              tag="a"
              class="btn-cart"
            >
              <span>{{ product.shopstatus === 'OPEN' ? 'Visit' : 'Closed' }}</span>
            </b-button>
            <b-button
              v-if="isThirdParty(product)"
              :variant="product.shopstatus === 'OPEN' ? 'primary' : 'danger'"
              tag="a"
              class="btn-cart"
              @click="goToThirdParty(product)"
            >
              <span>{{ product.shopstatus === 'OPEN' ? 'Visit' : 'Closed' }}</span>
            </b-button>
          </div>
        </div>
      </b-card>
    </section>

    <portal to="content-renderer-sidebar-detached-left">
      <store-side-bar
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @price-range-change="priceRangeChange"
      />
    </portal>
  </div>
</template>

<script>
import { BInputGroup, BFormInput, BInputGroupAppend, BRow, BCol, BCard, BLink, BImg, BCardBody, BCardText, BButton, BFormRadioGroup, BFormRadio } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, useResponsiveAppLeftSidebarVisibility } from '@/@core/comp-functions/ui/app'
// import UserAddresses from '@/@core/layouts/shared/UserAddresses.vue'
import { isUserLoggedIn } from '@/auth/utils'
import LocationBar from '@/layouts/components/LocationBar.vue'
import eventBus from '@/libs/event-bus'
import { useUserUi } from './useUser'
import StoreSideBar from './store/StoreSideBar.vue'

export default {
  components: {
    StoreSideBar,
    LocationBar,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BRow,
    BCol,
    BCard,
    BLink,
    BImg,
    BCardBody,
    BCardText,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    // UserAddresses,
  },
  data() {
    return {
      searchQuery: '',
      addressInfo: [],
      itemView: 'grid-view grid-view-3',
      storeList: {},
      filteredStore: [],
      filterOptions: {
        selectedQuickFilter: '',
        quickFilters: [{ text: 'All', value: '' }],
        showPriceRange: true,
        priceRange: '',
        selectedCategory: '',
        categoryFilters: [],
      },
    }
  },
  watch: {
    searchQuery: {
      handler() {
        this.beginSearch()
      },
    },
    'filterOptions.selectedQuickFilter': {
      handler() {
        this.getStoreList()
      },
    },
    'filterOptions.selectedCategory': {
      handler() {
        this.getStoreList()
      },
    },
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      mqShallShowLeftSidebar,
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeOccured)
    eventBus.$off('location-searched')
    eventBus.$off('new-address-selected')
  },
  mounted() {
    eventBus.$on('location-searched', payload => {
      if (!payload.isCustom && payload.geometry) {
        this.getStoreList(`latitude=${payload.geometry.location.lat()}&longitude=${payload.geometry.location.lng()}`)
      } else if (payload.isReset) {
        this.getStoreList()
      } else if (payload.lat && payload.lng) {
        this.getStoreList(`latitude=${payload.lat}&longitude=${payload.lng}`)
      }
    })
    eventBus.$on('new-address-selected', payload => {
      let defaultLatLong = ''
      if (payload && payload.latitude && payload.longitude) {
        defaultLatLong = `latitude=${payload.latitude}&longitude=${payload.longitude}`
      }
      this.getStoreList(defaultLatLong)
    })
    this.resizeOccured()
    window.addEventListener('resize', this.resizeOccured)
    showLoader()
    if (isUserLoggedIn()) {
      const { userAddressInfo } = useUserUi()
      userAddressInfo()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.addressInfo = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    }
    this.getStoreList()
  },
  methods: {
    isFoodCategory(product) {
      if (!product) {
        return false
      }
      if (!product.storetype) {
        return false
      }
      return product.storetype.category === 'FOOD'
    },
    resizeOccured() {
      if (window.innerWidth <= 767) {
        this.itemView = 'list-view'
      }else{
        this.itemView = 'grid-view grid-view-3'
      }
    },
    getStoreImage(product) {
      if (Array.isArray(product.photos)) {
        const isImage = product.photos.find(x => x.is_main && x.photo)
        if (isImage) {
          return this.$helpers.imageHelper(isImage.photo)
        }
      }
      return this.$helpers.imageHelper(product.picture)
    },
    priceRangeChange(changeValue) {
      this.filterOptions.priceRange = changeValue
      this.getStoreList()
    },
    getStoreList(defaultLatLong = '') {
      showLoader()
      const extraFilters = []
      if (defaultLatLong) {
        extraFilters.push(defaultLatLong)
      }
      if (this.filterOptions.selectedCategory) {
        extraFilters.push(`filter=${this.filterOptions.selectedCategory}`)
      }
      if (this.filterOptions.selectedQuickFilter) {
        extraFilters.push(`qfilter=${this.filterOptions.selectedQuickFilter}`)
      }
      if (this.filterOptions.priceRange) {
        extraFilters.push(`price_range=${this.filterOptions.priceRange}`)
      }
      if (window.SuperApp.getters.userCity() && !isUserLoggedIn()) {
        extraFilters.push(`city_id=${window.SuperApp.getters.userCity()}`)
      }
      const { userStoreList } = useUserUi()
      userStoreList({
        query: extraFilters.join('&'),
        baseUrl: this.$route.params.store,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.storeList = data.responseData
            window.storeList = data.responseData
            if (this.filterOptions.quickFilters.length === 1) this.getStoreCuisines()
            this.beginSearch()
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getCategory(product) {
      return product.categories
        ? product.categories
            .map(x => x.store_category_name)
            .slice(0, 10)
            .join(', ')
        : ''
    },
    getStoreCuisines() {
      const { storeCuisines } = useUserUi()
      if (this.storeList.length === 0) return
      this.filterOptions.quickFilters = []
      this.filterOptions.categoryFilters = []
      storeCuisines(this.storeList[0].store_type_id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.filterOptions.categoryFilters = data.responseData.map(x => ({
              text: x.name,
              value: x.name,
            }))
            this.filterOptions.categoryFilters.unshift({
              text: 'All',
              value: '',
            })
            if (this.storeList.find(x => x.storetype && x.storetype.category === 'FOOD')) {
              this.filterOptions.quickFilters.unshift({
                text: 'Free Delivery',
                value: 'freedelivery',
              })
              this.filterOptions.quickFilters.unshift({
                text: 'Non Veg',
                value: 'non-veg',
              })
              this.filterOptions.quickFilters.unshift({
                text: 'Pure Veg',
                value: 'pure-veg',
              })
            }
            this.filterOptions.quickFilters.unshift({
              text: 'All',
              value: '',
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    isThirdParty(product) {
      return product.third_party && product.third_party_url
    },
    getStoreUrl(product) {
      return `/user/store/details/${product.slug}`
    },
    goToThirdParty(product) {
      if (product.third_party === 1) {
        this.$swal({
          title: this.$t('You will be redirected to a third party website'),
          iconHtml: this.$helpers.swalIcon('alert.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            setTimeout(() => {
              window.open(product.third_party_url, '_BLANK')
            }, 100)
          }
        })
      }
    },
    beginSearch() {
      if (this.searchQuery) {
        this.filteredStore = this.storeList.filter(
          x =>
            // eslint-disable-next-line
            x.store_name && x.store_name.toLowerCase().includes(this.searchQuery.toLowerCase()),
        )
      } else {
        this.filteredStore = this.storeList
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.result-toggler {
  height: 40px;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  justify-content: center;
  padding-top: 0;
}

.ecommerce-application .list-view .ecommerce-card .item-img img {
  border-top-right-radius: 0;
}
</style>

<style>
@media only screen and (max-width: 767px) {
  .store-side-bar .wrap-sidebar-filters {
    height: 100%;
    background: #fff;
  }

  .store-side-bar .wrap-sidebar-filters .card {
    box-shadow: none !important;
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .user-store-list {
    .grid-view .ecommerce-card .item-img {
      min-height: 10.85rem;

      img {
        height: 150px;
      }
    }

    .list-view .ecommerce-card {
      grid-template-columns: 1fr 1fr;

      .item-img a {
        height: 100%;

        img {
          height: 100%;
        }
      }

      .item-options .btn-cart {
        margin-top: 0 !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .ecommerce-application .list-view .ecommerce-card .item-img {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ecommerce-application .list-view .ecommerce-card {
    p.card-text.item-description.mb-0 {
      display: none;
    }

    .card-body {
      padding-bottom: 0;
    }

    .product-timings-data span {
      display: block;
      margin: 0 !important;
    }

    .item-name a {
      -webkit-line-clamp: inherit;
    }
  }
}
</style>
